<template>
    <div class="container d-flex align-items-center justify-content-center">
        <div id="carouselExampleAutoplaying" class="carousel slide mt-5" data-bs-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <img src="../assets/davide.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/spritz.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/image6.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/image7.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/WhatsApp Image 2023-04-04 at 11.36.37 (1).jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/WhatsApp Image 2023-04-04 at 11.36.37.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/WhatsApp Image 2023-04-04 at 11.36.38.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/WhatsApp Image 2023-04-04 at 11.56.23.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/pruaisole.jpeg" class="d-block" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="../assets/pruavela.jpeg" class="d-block" alt="...">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.carousel {
    width: 300px !important;
    height: 220px !important;
    /* background-color: black; */
}

.carousel-item{
    width: 100% !important;
    height: 100% !important;
}

.carousel-inner {
    width: 300px !important;
    height: 220px !important;
    /* background-color: black; */
}

img {
    /* border-radius: 20px; */
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

@media screen and (min-width: 375px) {
    .carousel {
        width: 340px !important;
        height: 250px !important;
    }

    .carousel-inner {
        width: 340px !important;
        height: 250px !important;
    }
}

@media screen and (min-width: 576px) {
    .carousel {
        width: 400px !important;
        height: 250px !important;
    }

    .carousel-inner {
        width: 400px !important;
        height: 250px !important;
    }
}


@media screen and (min-width: 768px) {

    .carousel {
        width: 460px !important;
        height: 280px !important;
    }

    .carousel-inner {
        width: 460px !important;
        height: 280px !important;
    }

}

@media screen and (min-width: 992px) {

    .carousel {
        width: 550px !important;
        height: 320px !important;
    }

    .carousel-inner {
        width: 550px !important;
        height: 320px !important;
    }
}

@media screen and (min-width: 1200px) {

    .carousel {
        width: 750px !important;
        height: 470px !important;
    }

    .carousel-inner {
        width: 750px !important;
        height: 470px !important;
    }

}
</style>