<template>
    <div class="container mt-4">
        <div class="titolo">
            Dove siamo?
        </div>

        <div class="mt-4 luogo">
            <div class="d-flex justify-content-center ">
                <img src="../assets/dove-siamo2.png" alt="">
            </div>

            <div class="fs-5 mt-4 text-center info">
                <i class="fa-solid fa-location-dot text-danger"></i> <br>
                Vieste 71019, FG <br>
                Scalo Marittimo Sud <br>
                Pontile "ONDA" <br>
                <b>Telefono:</b> 3349177111 <br>
                <a href="https://www.google.com/maps/place/41%C2%B053'12.2%22N+16%C2%B010'38.9%22E/@41.886709,16.1769178,19z/data=!3m1!4b1!4m4!3m3!8m2!3d41.886708!4d16.177465">
                    <button class="mt-3">Visitaci</button>
                </a>
                
            </div>
        </div>

        <div class="mt-4 luogo">
            <div class="d-flex justify-content-center ">
                <img src="../assets/luogo-tim.png" alt="">
            </div>

            <div class="fs-5 mt-4 text-center info">
                <i class="fa-solid fa-location-dot text-danger"></i> <br>
                Vieni a trovarci in: <br>
                Vieste 71019, FG <br>
                Viale XXIV Maggio,68 <br>
                Centro TIM <br>
                <b>Telefono:</b> 3349177111 <br>
                <a href="https://www.google.com/maps/place/Negozio+TIM/@41.8820235,16.1721363,17z/data=!3m1!4b1!4m6!3m5!1s0x1337c816b6d4377f:0x1e663cf7e7e24252!8m2!3d41.8820195!4d16.1747112!16s%2Fg%2F1tgl6h1l">
                    <button class="mt-3">Visitaci</button>
                </a>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

img{
    width: 300px;
}

/* ui verse */

button {
    padding: 0.8em 1.8em;
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    background-color: #3b5471;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: #ffffff;
}

button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #586f8a;
    transition: .5s ease;
    display: block;
    z-index: -1;
}

button:hover::before {
    width: 105%;
}

button:hover {
    color: #ffffff;
}



@media screen and (min-width:768px) {
    .luogo{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .info{
        text-align: start !important;
    }

    .titolo{
        font-size: 45px;
    }
}

@media screen and (min-width:992px) {
    img{
        width: 400px;
    }
}

@media screen and (min-width:1200px) {
    .titolo{
        font-size: 50px;
    }
}

</style>