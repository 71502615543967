<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-tertiary">
      <div class="container ">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo-ritagliato-cerchio.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <!-- <span class="navbar-toggler-icon "></span> -->
          <div class="linea1"></div>
          <div class="linea2 mt-2 mb-2"></div>
          <div class="linea3"></div>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ms-auto text-center">
            <a class="nav-link" aria-current="page" href="#home">Home</a>
            <a class="nav-link" href="#lei">Barca</a>
            <a class="nav-link" href="#cose">Itinerari</a>
            <a class="nav-link" href="#servizi">Servizi</a>
            
            <a class="nav-link" href="#contattaci">Contattaci</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

}
</script>


<style scoped lang="scss">

.navbar-toggler{
  border: 1px solid white !important;
  padding: 12px;
}
.linea1{
  width: 22px;
  height: 2px;
  border-radius: 35px;
  background-color: white;
}
.linea2{
  width: 22px;
  height: 2px;
  border-radius: 35px;
  background-color: white;
}
.linea3{
  width: 22px;
  height: 2px;
  border-radius: 35px;
  background-color: white;
}

.nav-link{
  font-weight: bold !important;
  color: white !important;
}

img{
  width: 70px;
  border-radius: 50%;
}

.navbar{
  background-color: #3b5471;
  
}

</style>
