<template>
    <div class="box">
        <div class="container d-flex text-center justify-content-evenly align-items-center h-75">

            <div class="text-light">
                <i class="fa-solid fa-heart fs-1"></i>
                <div class="pregio fs-3">
                    Passione
                </div>
            </div>

            <div class="text-light">
                <i class="fa-solid fa-hand-fist fs-2"></i>
                <div class="pregio fs-3">
                    Affidabilità
                </div>
            </div>

            <div class="text-light">
                <i class="fa-solid fa-star fs-2"></i>
                <div class="pregio fs-3">
                    Esperienza
                </div>
            </div>
            

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.pregio{
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
}

.box {
    --mask:
    radial-gradient(52.84px at 50% calc(100% - 73.50px),#000 99%,#0000 101%) calc(50% - 60px) 0/120px 100%,
    radial-gradient(52.84px at 50% calc(100% + 43.50px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
    height: 200px;
    width: 100vw;
    background-color: #3b5471;
}
</style>