<template>
    <div class="container text-center mt-5">
        <div class="d-flex justify-content-evenly align-items-center">
            <div>
                <div class="immagine carmine"></div>
                <div class="mt-2"> <span>Comandante</span> </div>
                <div class="fst-italic">CARMINE</div>
            </div>
            <div>
                <div class="immagine davide"></div>
                <div class="mt-2"> <span>Crew</span> </div>
                <div class="fst-italic">DAVIDE</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.immagine {
    margin: auto;
    width: 145px;
    height: 145px;
    background-color: #3b5471;
    border-radius: 50%;
    
}

.carmine{
    background-image: url(../assets/carmine.png);
    background-size: cover;
}

.davide{
    background-image: url(../assets/davide.png);
    background-size: cover;
}

span {
    font-weight: 900;
    color: #3b5471;
}

@media screen and (min-width:375px) {

    .immagine {
        width: 180px;
        height: 180px;
    }
}
@media screen and (min-width:576px) {

    .immagine {
        width: 220px;
        height: 220px;
    }
}
@media screen and (min-width:768px) {

    .immagine {
        width: 240px;
        height: 240px;
    }
}
@media screen and (min-width:992px) {

    .immagine {
        width: 290px;
        height: 290px;
    }
}
@media screen and (min-width:1200px) {

    .immagine {
        width: 320px;
        height: 320px;
    }
}
@media screen and (min-width:1400px) {

    .immagine {
        width: 340px;
        height: 340px;
    }
}


</style>