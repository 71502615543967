<template>
    <div class="container mt-5 pb-5 mb-5" id="lei">

        <div class="titolo">
            Lei...
        </div>



        <div class="d-flex align-items-center justify-content-center tutto">

            <div class="contenitore-foto d-flex flex-wrap align-items-center justify-content-center gap-5">

                <div class="d-flex align-items-center justify-content-center">
                    <div class="imga"></div>
                </div>




                <div>
                    <ul class="text-center">
                        <li>
                            <span>Nome:</span> <br> Seven
                        </li>
                        <li>
                            <span>Cantiere:</span> <br> Gobbi 375sc
                        </li>
                        <li>
                            <span>Dimensioni:</span> <br>
                            Lunghezza 12 mt <br>
                            Larghezza 4 mt
                        </li>
                        <li>
                            <span>Motori:</span> <br>
                            Volvo Penta <br>
                            2x 300cv
                        </li>
                    </ul>
                </div>
            </div>


            <div class="box  box-here p-3 mt-5"> <span></span>
                <span>Cruiser</span> dall'ottima vivibilità, all'interno 2 cabine, 2 bagni, la dinetta con salotto e una
                comoda cucina ,
                è dotato di tutti i <span>comfort e optional</span> tra cui servizio WIFI, aria condizionata, frigo bar
                esterno e tanto
                altro. <br>
                Spazio <span>prendisole di prua</span> per godere una vista mozzafiato a velocità di crociera per un
                soggiorno rilassante
                e divertente. <br>
                Ampio <span>pozzetto di poppa</span> con tavolo e un comodo salotto per gustare le specialità tipiche
                pugliesi
                accompagnate dai vini e prosecchi scelti. <br>
                <span>Plancetta di poppa in teak</span> con scala bagno e doccia caldo/freddo. <br>
                L'imbarcazione è disponibile in qualsiasi giorno della settimana, per un giorno o più. <br>
                Seven Yacht sarà sempre al tuo servizio con passione, affidabilità e competenza. <br>
                <span>Trasforma le tue vacanze in avventure magiche ed emozioni affascinanti!</span>
            </div>

        </div>

        <div class="box box-no p-3 m-auto mt-5">
            <span>Cruiser</span> dall'ottima vivibilità, all'interno 2 cabine, 2 bagni, la dinetta con salotto e una comoda
            cucina ,
            è dotato di tutti i <span>comfort e optional</span> tra cui servizio WIFI, aria condizionata, frigo bar esterno
            e tanto
            altro. <br>
            Spazio <span>prendisole di prua</span> per godere una vista mozzafiato a velocità di crociera per un soggiorno
            rilassante
            e divertente. <br>
            Ampio <span>pozzetto di poppa</span> con tavolo e un comodo salotto per gustare le specialità tipiche pugliesi
            accompagnate dai vini e prosecchi scelti. <br>
            <span>Plancetta di poppa in teak</span> con scala bagno e doccia caldo/freddo. <br>
            L'imbarcazione è disponibile in qualsiasi giorno della settimana, per un giorno o più. <br>
            Seven Yacht sarà sempre al tuo servizio con passione, affidabilità e competenza. <br>
            <span>Trasforma le tue vacanze in avventure magiche ed emozioni affascinanti!</span>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

/* .corsivo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;

} */

.imga {
    background-image: url(../assets/leifoto.jpg);
    background-size: cover;
    background-position: center;
    width: 300px;
    height: 300px;
    margin-top: 35px;
    border-radius: 50%;
    border: 6px solid #3b5471;
    box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.41);
}

span {
    font-weight: 900;
    color: #3b5471;
}

ul {
    padding-left: 0;
}

li {
    list-style-type: none;
    margin-top: 30px;
}

.box {
    width: 300px !important;
    border: 4px solid #3b5471;
    box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.41);
}

.box-here {
    display: none;
}


@media screen and (min-width:375px) {
    .imga {
        width: 340px;
        height: 340px;
    }

    .contenitore-foto {
        flex-direction: column;
    }

    .box {
        width: 340px !important;
        border: 4px solid #3b5471;
        box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.41);
    }
}

/* @media screen and (min-width:576px) {
    .imga {
        width: 340px;
        height: 340px;
    }

    .contenitore-foto {
        flex-direction: column;
    }
} */


@media screen and (min-width: 768px) {
    .imga {
        width: 370px;
        height: 370px;
    }

    .contenitore-foto {
        flex-direction: row;
    }

    .box {
        width: 600px !important;
    }

    .titolo {
        font-size: 45px;
    }
}

@media screen and (min-width: 992px) {
    .box {
        width: 670px !important;
    }

}

@media screen and (min-width: 1200px) {
    .tutto {
        justify-content: space-between !important;
    }

    .box-here {
        display: inline !important;
    }

    .box-no {
        display: none;
    }

    .box {
        width: 450px !important;
    }

    .titolo {
        font-size: 50px;
    }
}

@media screen and (min-width: 1400px) {
    .box-here {
        display: inline !important;
    }

    .box-no {
        display: none;
    }

    .box {
        width: 650px !important;
    }

    img {
        width: 320px !important;
    }
}
</style>