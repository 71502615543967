<template>
    <div class="sfondo" id="cose">
        <div class="container">
            <div class="titolo pt-3">
                Cosa puoi fare?
            </div>

            <div class="mt-4 pb-4">

                <div class="iti-card">


                    <!-- APERICENA IN BARCA -->

                    <div class="card-totale">
                        <div class="text-center immagine">
                            <img src="../assets/vieste-in-notturna-1.jpg" alt="">
                        </div>

                        <div class=" dettagli">
                            <h3 class="pt-2">Apericena</h3>
                            <p>
                                Godi di un indimenticabile momento di puro relax in mare davanti alla vista più suggestiva
                                del paese, accompagnata da una ricca apericena composta da prosecco/vino a scelta, da
                                prodotti tipici locali.
                            </p>


                            <!-- Button trigger modal -->
                            <button type="button" class="bottone" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                Leggi di più
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title" id="exampleModalLabel">Apericena</h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            <div class="text-center mt-3">
                                                <span class="m-auto fs-5">50&euro;</span> /a persona
                                            </div>

                                            <br>

                                            <div>

                                                <ul>
                                                    <li> <span>Durata:</span> 2 ore e mezza circa</li>
                                                    <li><span>Numero di partecipanti:</span> 1-8</li>
                                                </ul>


                                                <div>
                                                    <span>Cosa avrai a bordo:</span>
                                                </div>

                                                <ul>
                                                    <li>Skipper privato</li>
                                                    <li>Steward privato</li>
                                                    <li>Stuzzichini e specialità pugliesi e non solo</li>
                                                    <li>Bottiglia/e di prosecco</li>
                                                    <li>Vino locale</li>
                                                </ul>

                                                <br>

                                                L'Apericena in Barca al Tramonto è realizzata ponendo attenzione alle vostre
                                                esigenze, un'atmosfera accompagnata da buona musica e da una gustosa
                                                apericena a base di prodotti tipici e prosecco. <br>
                                                Ogni esperienza a bordo di Seven Yacht è interamente personalizzabile...su
                                                misura!
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>



                    <!-- COSTA GARGANICA -->

                    <div class="card-totale mt-4">
                        <div class="text-center immagine">
                            <img src="../assets/vieste-min.webp" alt="">
                        </div>

                        <div class=" dettagli">
                            <h3 class="pt-2">Costa Garganica </h3>
                            <p>
                                Escursione lungo la costa con panorami mozzafiato di ben 10 miglia di navigazione fino agli
                                imponenti faraglioni della Baia delle Zagare per esplorare le grotte marine e godersi i
                                bagni nelle spettacolari baie e calette.
                            </p>
                            <!-- <h2 class="">
                                &euro; 490 <span>(max. 8 ospiti)</span>
                            </h2> -->

                            <!-- Button trigger modal -->
                            <button type="button" class="bottone" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                Leggi di più
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title" id="exampleModalLabel">Costa Garganica</h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body ">
                                            <div class="text-center">
                                                <span class="m-auto fs-5">Escursione Giornaliera</span>
                                            </div>
                                            <div class="text-center">
                                                <span class="m-auto fs-5">490&euro;</span>
                                            </div>

                                            <br>

                                            <ul>
                                                <li>
                                                    <span>Durata noleggio:</span> 8 ore circa
                                                </li>
                                                <li>
                                                    <span>Numero di partecipanti:</span> 1-8
                                                </li>
                                                <li>
                                                    <span>Tipologia noleggio:</span> uso esclusivo e privato della barca con
                                                    skipper e steward incluso.
                                                </li>
                                                <li>
                                                    <span>Ora del check in:</span> 9:00
                                                </li>
                                                <li>
                                                    <span>Ora del chek out:</span> 17.30 circa
                                                </li>
                                            </ul>

                                            <div>
                                                L'escursioni private by Seven Yacht sono personalizzabili nel programma del
                                                viaggio e prevede anche orari flessibili in base alle Vostre esigenze.
                                            </div>

                                            <br>

                                            <ul>
                                                <li>
                                                    <span>Incluso nel prezzo:</span> skipper e steward
                                                </li>
                                                <li>
                                                    <span>Escluso dal prezzo:</span> carburante e cambusa
                                                </li>
                                            </ul>

                                            <div>
                                                A bordo vi serviamo un ricco aperipranzo con le specialità pugliesi
                                                accompagnato
                                                dal prosecco e bevande a scelta. <br>
                                            </div>

                                            <br>

                                            <ul>
                                                <li>
                                                    <span>Costo aperipranzo:</span> 20€ a persona
                                                </li>
                                            </ul>

                                            <div>
                                                I prezzi delle escursioni in barca giornaliere possono variare in base
                                                al numero di partecipanti e al servizio scelto. <br>
                                                Le nostre escursioni toccano le baie più famose tra cui: Baia San Felice,
                                                Baia
                                                Vignanotica, baia delle Zagare, Grotta Sfondata, Baia di Pugnochiuso,
                                                Baia della Pergola, Baia di Campi , le grotte marine e il Pizzomunno
                                                (monolite, simbolo di Vieste).
                                            </div>

                                            <br>

                                            <ul>
                                                <li>
                                                    <span>Su richiesta:</span> l'escursione in barca di un giorno intero può
                                                    proseguire con un fantastico aperitivo/apericena al
                                                    tramonto.
                                                </li>
                                            </ul>

                                            <div>
                                                Prenotalo subito e vivi la magia di un'esperienza senza pensieri all'insegna
                                                del
                                                mare, del relax e del massimo comfort.
                                                <br>
                                            </div>

                                            <br>

                                            <hr>


                                            <div class="text-center">
                                                <span class="m-auto fs-5">Escursione Mezza Giornata</span>
                                            </div>
                                            <div class="text-center">
                                                <span class="m-auto fs-5">390&euro;</span>
                                            </div>
                                            <br>

                                            <div>
                                                <ul>
                                                    <li>
                                                        <span>Durata noleggio:</span> 4 ore circa
                                                    </li>
                                                    <li>
                                                        <span>Numero di partecipanti:</span> 1-8
                                                    </li>
                                                    <li>
                                                        <span>Tipologia noleggio:</span> uso esclusivo e privato della barca
                                                        con skipper
                                                        e
                                                        steward incluso.
                                                    </li>
                                                </ul>

                                                <span>Tour Mattina</span>
                                                <ul>
                                                    <li>
                                                        Ora del Check in: 9:00
                                                    </li>
                                                    <li>
                                                        Ora del Chek out: 13:00 circa
                                                    </li>
                                                </ul>

                                                <span>Tour Pomeriggio</span>
                                                <ul>
                                                    <li>
                                                        Ora del Check in: 14:00
                                                    </li>
                                                    <li>
                                                        Ora del Chek out: 18:00 circa
                                                    </li>
                                                </ul>


                                                <div>
                                                    L'escursioni private by Seven Yacht sono personalizzabili nel programma
                                                    del
                                                    viaggio e prevede orari flessibili in base alle Vostre esigenze.
                                                </div>
                                                <br>

                                                <ul>
                                                    <li><span>Incluso nel prezzo:</span> skipper e steward</li>
                                                    <li><span>Escluso dal prezzo:</span> carburante</li>
                                                </ul>

                                                <div>
                                                    I prezzi delle escursioni in barca giornaliere possono variare in base
                                                    al numero di partecipanti e al servizio scelto. <br>
                                                    Le nostre escursioni toccano le baie più famose tra cui: Baia San
                                                    Felice, Baia
                                                    Vignanotica, baia delle Zagare, Grotta Sfondata, Baia di Pugnochiuso,
                                                    Baia della Pergola, Baia di Campi , le grotte marine e il Pizzomunno
                                                    (monolite, simbolo di Vieste).
                                                </div>

                                                <br>

                                                <ul>
                                                    <li>
                                                        <span>Su richiesta:</span> l'escursione in barca di mezza giornata
                                                        pomeridiana
                                                        può
                                                        proseguire con un fantastico aperitivo/apericena al
                                                        tramonto.
                                                    </li>
                                                </ul>

                                                <div>
                                                    Prenotalo subito e vivi la magia di un'esperienza senza pensieri
                                                    all'insegna
                                                    del
                                                    mare, del relax e del massimo comfort.
                                                    <br>
                                                </div>






                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- ISOLE TREMITI -->

                    <div class="card-totale mt-4">
                        <div class="text-center immagine">
                            <img src="../assets/isole-tremiti.jpg" alt="">
                        </div>

                        <div class=" dettagli">
                            <h3 class="pt-2">Isole Tremiti </h3>
                            <p>
                                Tour dell'arcipelago alla scoperta delle meraviglie naturalistiche come mare limpido, i
                                fondali puliti, l'aria pura, il clima piacevole nonché la natura incontaminata.. tuffarsi
                                nelle acque cristalline e fare bagni nelle calette romantiche in totale libertà.
                            </p>
                            <!-- <h2 class="">
                                &euro; 790 <span>(max. 6 ospiti)</span>
                            </h2> -->

                            <!-- Button trigger modal -->
                            <button type="button" class="bottone" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                Leggi di più
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title" id="exampleModalLabel">Isole Tremiti</h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="text-center">
                                                <span class="m-auto fs-5">Escursione in un giorno</span>
                                            </div>
                                            <div class="text-center">
                                                <span class="m-auto fs-5">790&euro;</span>
                                            </div> <br>

                                            <ul>
                                                <li>
                                                    <span>Durata noleggio: </span> 9 ore circa
                                                </li>
                                                <li>
                                                    <span>Numero di partecipanti:</span> 1-6
                                                </li>
                                            </ul>

                                            <div>
                                                Uso esclusivo e privato della barca con skipper e
                                                steward
                                                incluso.
                                            </div> <br>

                                            <ul>
                                                <li>
                                                    <span>Ora del check in:</span> 9:00
                                                </li>
                                                <li>
                                                    <span>Ora del chek out:</span> 18 circa
                                                </li>
                                            </ul>

                                            <div>
                                                L'escursioni private by Seven Yacht sono personalizzabili nel programma del
                                                viaggio e prevede anche orari flessibili in base alle Vostre esigenze.
                                            </div> <br>

                                            <ul>
                                                <li><span>Incluso nel prezzo:</span> skipper e steward</li>
                                                <li><span>Escluso dal prezzo:</span> carburante e cambusa</li>
                                            </ul>

                                            <div>
                                                A bordo vi serviamo un ricco aperipranzo con le specialità pugliesi
                                                accompagnato
                                                dal prosecco e bevande a scelta.
                                            </div> <br>

                                            <ul>
                                                <li><span>Costo aperipranzo:</span> 20€ a persona</li>
                                            </ul>

                                            <div>
                                                I prezzi delle escursioni in barca giornaliere possono variare in base al
                                                numero
                                                di partecipanti e al servizio scelto.
                                            </div>
                                            <br>
                                            <div>
                                                Un'escursione in barca per visitare nel modo migliore questo arcipelago che
                                                ci riempe continuamente di meraviglie, dopo circa 30miglia da Vieste (il
                                                tempo di percorrenza circa un'ora e mezza ), toccheremo i punti d'interesse
                                                più suggestivi come statua di Padre Pio, Faraglioni dei Pagliaia, Punta del
                                                Diamante, Punta Secca, Cala Tonda, Cala degli Inglesi, Cala Marano, Scoglio
                                                dell'Elefante e tante altre spiagge uniche nel suo genere. <br>
                                                Prenotalo subito e vivi la magia di un'esperienza senza pensieri all'insegna
                                                del mare, del relax e del massimo comfort.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <!-- CROAZIA -->

                    <div class="card-totale mt-4">
                        <div class="text-center immagine">
                            <img src="../assets/lastovo-cover.webp" alt="">
                        </div>

                        <div class=" dettagli">
                            <h3 class="pt-2">Croazia </h3>
                            <p>
                                Minicrociera per godersi le bellezze incantevoli dell'arcipelago di Lastovo dove regalarvi i
                                momenti di una vera e propria oasi di pace e puro relax con bagni nel mare verdissimo e non
                                solo..
                            </p>
                            <!-- <div class="fw-bold" style="color: #3b5471;">A partire da</div>
                            <h2 class="">
                                &euro; 1.690 <span> 2gg (+ 400&euro; al giorno)</span>
                            </h2> -->

                            <!-- Button trigger modal -->
                            <button type="button" class="bottone" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                                Leggi di più
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable    ">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h3 class="modal-title" id="exampleModalLabel">Croazia</h3>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="text-center">
                                                <span class="m-auto fs-5">Minicrociera</span>
                                            </div>
                                            <div class="text-center">
                                                A partire da <br><span class="m-auto fs-5">1690&euro;</span>
                                            </div>

                                            <br>

                                            <ul>
                                                <li>
                                                    <span>Numero di partecipanti:</span> 1-6
                                                </li>
                                            </ul>

                                            <div>
                                                Noleggio privato ed esclusivo della barca con skipper e steward incluso.
                                            </div>

                                            <br>

                                            <ul>
                                                <li><span>Itinerario minicrociera:</span> Vieste -arcipelago di Lastovo
                                                    (Croazia)</li>
                                                <li><span>Distanza:</span> 58 miglia (tempo di percorrenza circa 2 ore e
                                                    mezza)</li>
                                                <li><span> Orario di partenza:</span> consigliabile mattina ore max 7/7.30
                                                </li>
                                                <li><span>Durata minicrociera:</span> minimo 2 giorni </li>
                                            </ul>

                                            <span>Incluso nel prezzo minicrociera 2 giorni:</span>
                                            <ul>
                                                <li>Skipper</li>
                                                <li>Steward - equipaggio </li>
                                                <li>Il pernotto con colazione in hotel Solitudo (posizionato davanti
                                                    l'imbarcazione) </li>
                                            </ul>

                                            <div>
                                                <span>Escluso dal prezzo:</span> carburante e cambusa (eventuale aperipranzo
                                                a bordo da concordare)
                                            </div>

                                            <br>


                                            <div>
                                                <span>Nota bene:</span> per ogni giorno in piu della vostra permanenza in
                                                Croazia si applica il costo di €490 (Il prezzo include Hotel, ormeggio ed
                                                eventuali altri costi amministrativi del parco)
                                            </div>

                                            <br>

                                            <div>
                                                La minicrociera by Seven Yacht al parco naturale dell'isola di Lastovo in
                                                Croazia è estremamente personalizzabile nel programma del viaggio e prevede
                                                anche orari flessibili in base alle Vostre esigenze.
                                            </div>

                                            <br>

                                            <div>
                                                In una vacanza da sogno su questa piccola isola di colore verde e mare blu
                                                ricco di vita all'insegna della semplicità, oltre ad ammirare durante
                                                l'escursione intorno all'isola il paradiso naturale come i promontori
                                                rocciosi mozzafiato, le spiagge di ciottoli incastonati tra le scogliere
                                                pieni di pesci ed incantevoli baie solitarie, la spiaggia top che merita la
                                                sosta e bagno è senza dubbi isolotto di Saplun a nord-est dell'isola. <br>
                                                Meta sopratutto di vacanzieri in barca e non solo escursioni, ma esperienze
                                                su misura!
                                            </div>

                                            <br>

                                            <span>
                                                Punti d'interesse
                                            </span>
                                            <ul>
                                                <li>Pasadur</li>
                                                <li>San Giorgio </li>
                                                <li>Skrivena luka</li>
                                                <li>Lucica</li>
                                                <li>Zaklopatica</li>
                                                <li>Saplun</li>
                                            </ul>

                                            <br>
                                            <br>

                                            <div>
                                                Il paradiso terrestre esiste! <br>
                                                Scoprilo con una minicrociera personalizzata in barca in Croazia e le sue
                                                isole!
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <div class="mt-5 pb-4">
                    <div class="text-center">
                        <h3>
                            Scarica le informazioni
                        </h3>
                    </div>
                    <div class="d-flex flex-column gap-3 align-items-center justify-content-evenly mt-5 bottoni-sezione">

                        <a href="https://drive.google.com/file/d/1JIgc14AdQEZOQ5Osj0lJ-5P9mCiadS3M/view?usp=share_link"><button class="bottone">Volantino</button></a>
                        <a href="https://drive.google.com/file/d/1DJTzscaDesGH0jZdOWkIheP75O3aUVJF/view?usp=share_link"><button class="bottone">Biglietto da visita</button></a> 
                        <a href="https://drive.google.com/file/d/1jGi5X8h61mUGwq54fUooRX4QD38J3vkm/view?usp=share_link"><button class="bottone">QR code</button></a>
                        

                    </div>
                </div>








            </div>

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.sfondo {
    width: 100vw;
    background-color: #3b547127;
}

.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

.iti-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    border-radius: 18px;
}

.immagine {
    margin: auto;
    width: 300px;
    height: 180px;
    border-radius: 20px;
    border: 3px solid #3b5471;
}

h3,
h2 {
    color: #3b5471;
    font-weight: bolder;
}

h3 {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    font-weight: 700;
}

.linea {
    width: 90vw;
    height: 2px;
    background-color: #3b5471;
    border-radius: 20px;
}

span {
    color: #3b5471;
    font-weight: bold;
}

p {
    color: #3b5471;
}


/* ui verse */

.bottone {
    padding: 0.6em 1.4em;
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    background-color: #3b5471;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: #ffffff;
}

.bottone::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #586f8a;
    transition: .5s ease;
    display: block;
    z-index: -1;
}

.bottone:hover::before {
    width: 105%;
}

.bottone:hover {
    color: #ffffff;
}

@media screen and (min-width: 425px) {

    .immagine {
        width: 400px;
        height: 200px;
    }

}

@media screen and (min-width: 480px) {

    .immagine {
        width: 410px;
        height: 230px;
    }

    .dettagli {
        margin: auto;
        width: 90% !important;
    }
}

@media screen and (min-width: 576px) {

    .immagine {
        width: 465px;
        height: 240px;
    }

    .dettagli {
        margin: auto;
        width: 90% !important;
    }

    
}

@media screen and (min-width: 768px) {

    img {
        width: 100%;
    }

    .dettagli {
        width: 50% !important;
    }

    .card-totale {
        display: flex;
    }

    .immagine {
        text-align: left;
        width: 550px;
        margin-right: 20px;
    }

    .titolo {
        padding-top: 30px !important;
    }

    .bottoni-sezione{
        gap: 0;
        flex-direction: row !important;
    }
}

@media screen and (min-width: 1024px) {

    .titolo {
        font-size: 45px;
    }

    .immagine {
        width: 520px;
        height: 260px;
    }
}

@media screen and (min-width: 1200px) {

    img {
        width: 100%;
    }


    .immagine {
        text-align: left;
        width: 520px;
        height: 260px;
        margin-right: 20px;
    }

    .titolo {
        font-size: 50px;
    }
}

@media screen and (min-width: 1400px) {

    img {
        width: 100%;
    }


    .immagine {
        text-align: left;
        width: 520px;
        height: 260px;
        margin-right: 20px;
    }

    .titolo {
        font-size: 50px;
    }
}
</style>