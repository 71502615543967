<template>
  <div id="app">


    <HeaderComp  class="header" />
    <JumboComp />
    <SloganComp />
    <ChiComp />
    <CardComp />
    <DoveComp />
    <LeiComp />
    <CoseComp />
    <WaveComp />
    <ServiziComp />
    <CaroselloComp />
    <!-- <CarosComp /> -->
    <DomandeComp />
    <RecensioniComp/> 
    <ContactsComp />
    <FooterComp />




  </div>
</template>

<script>
import HeaderComp from './components/HeaderComp.vue'
import JumboComp from './components/JumboComp.vue'
import SloganComp from './components/SloganComp.vue'
import ChiComp from './components/ChiComp.vue'
import CardComp from './components/CardComp.vue'
import DoveComp from './components/DoveComp.vue'
import LeiComp from './components/LeiComp.vue'
import CoseComp from './components/CoseComp.vue'
import WaveComp from './components/WaveComp.vue'
import ServiziComp from './components/ServiziComp.vue'
import CaroselloComp from './components/CaroselloComp.vue'
/* import CarosComp from './components/CarosComp.vue' */
import DomandeComp from './components/DomandeComp.vue'
import RecensioniComp from './components/RecensioniComp.vue'
import ContactsComp from './components/ContactsComp.vue'
import FooterComp from './components/FooterComp.vue'


import 'swiper/css';

  import 'swiper/css/navigation';
  import 'swiper/css/pagination';


export default {
  name: 'App',
  components: {
    HeaderComp,
    JumboComp,
    SloganComp,
    ChiComp,
    CardComp,
    DoveComp,
    LeiComp,
    CoseComp,
    WaveComp,
    ServiziComp,
    CaroselloComp,
    DomandeComp,
    RecensioniComp,
    ContactsComp,
    FooterComp,
  }
}
</script>

<style lang="scss">

/* 

#3b5471 -colore primario

*/

body{
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  color: black;
  overflow-x: hidden;
}



</style>
