<template>
    <div class="container  mt-4" id="servizi">
        <div class="titolo text-center">
            I nostri servizi
        </div>
        <div class="text-center">
            <div class="mt-3">
                <ul class="">
                    <!-- <li>
                        <i class="fa-solid fa-utensils icone me-2"></i> Aperitivo a bordo
                    </li> -->
                    
                    
                    
                    <li><i class="fa-solid fa-sun icone me-2"></i> Tendalino</li>
                    <li><i class="fa-solid fa-music me-2"></i> Impianto stereo</li>
                    <li><i class="fa-solid fa-wifi icone me-2"></i> Internet WiFi</li>
                    <li><i class="fa-solid fa-fan icone me-2"></i> Aria Condizionata</li>
                    <li><i class="fa-solid fa-toilet icone me-2"></i> WC a Bordo</li>
                    <li><i class="fa-solid fa-mug-hot me-2"></i> Macchina del caffè</li>
                    <li><i class="fa-solid fa-dice-d6 me-2"></i> Macchina per il ghiaccio</li>
                    <li><i class="fa-solid fa-shower icone me-2"></i> Doccia a Poppa</li>
                    <li><i class="fa-solid fa-martini-glass me-2"></i> Frigo bar esterno</li>
                    <li><i class="fa-solid fa-spoon me-2"></i> Tavola da paddle (sup)</li>
                    
                    
                    
                    
                    
                    
                </ul>
            </div>  
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

li{
    list-style-type: none;
    margin-top: 15px;
    color: #3b5471;
}

ul{
    padding-left: 0px ;
}



@media screen and (min-width: 1024px){
    .titolo{
        font-size: 45px;
    } 

    li{
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px){
    .titolo{
        font-size: 50px;
    } 

    li{
        font-size: 20px;
    }
}

</style>