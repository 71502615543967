<template>
    <div class="container pb-4" id="chi">

        <div class="text-center mt-4">
            <img src="../assets/rosaventi3.png" alt="" class="stella">
        </div>

        <div class="titolo mt-4 ">
            Chi siamo?
        </div>
        <div class="d-flex">

            <div class="descrizione mt-3"> 
                <span>Seven Yacht</span> <br>
                nasce da una grande passione per il mare e dal rispetto per la meravigliosa costa di Vieste.
                <br>
                Offriamo un servizio di <span>tour ed escursioni private</span> accompagnati dal nostro <span>skipper professionista</span>
                Carmine e un equipaggio esperto per garantire il <span>massimo comfort</span>, servizi di <span>qualità</span> e <span>sicurezza</span> in mare.
                Vi accompagneremo alla scoperta dei diversi itinerari di posti esclusivi e ricchi di fascino dove la natura
                si
                mostra nella sua massima espressione d'arte. <br>
                Scopri il lato segreto della meravigliosa <span>costa Garganica</span>, le famose <span>grotte</span> del promontorio, il mare
                cristallino
                dell"arcipelago delle <span>Isole Tremiti</span> e minicrociera nelle incantevoli meraviglie naturalistiche dell'isola di
                Lastovo in <span>Croazia</span>. <br>
                Siamo a vostra disposizione per crociere personalizzate di una intera o mezza giornata, un'esperienza con
                <span>apericena</span> al tramonto e altri esclusivi tour <span>su misura</span>. <br>
                Noleggia questa splendida barca e preparati a vivere un'esperienza indimenticabile. <br>
                <span>Ti aspettiamo a bordo!</span> <br> <!-- ....<span>confortevole ed esclusivo!</span> <br> -->
                <span class="corsivo fs-2">Cosa aspetti? Contattaci!</span>
            </div>

            <div class="d-none immagine">
                <img src="../assets/chisiamo.jpeg" alt="" class="">
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
            <img src="../assets/chisiamo.jpeg" alt="" style="width: 280px; margin-top: 25px; border: 3px solid #3b5471; padding: 10px;" class="immagine1">
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.stella{
    margin: 0px;
    width: 45px;
}


.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

.corsivo{
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    
}

.descrizione {
    color: #3b5471;
    padding-left: 10px;
}

span {
    font-weight: bold;
}

img {
    width: 400px;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

@media screen and (min-width: 768px) {
    .titolo {
        font-size: 45px;
    }

    .stella{
        width: 50px;
    }

    .immagine1{
        width: 320px !important ;
    }
}

@media screen and (min-width: 992px) {
    .descrizione{
        padding: 15px;
    }

    img {
        width: 380px;
        margin-top: 25px;
    }

    .immagine{
        display: block !important;
    }

    .immagine1{
        display: none;
    }

}
@media screen and (min-width: 1200px) {
    .titolo {
        font-size: 50px;
    }

}
@media screen and (min-width: 1400px) {
    .descrizione{
        padding: 45px;
    }

    img {
        margin-right: 60px;
    }

    .stella{
        width: 60px;
        height: 55px;
    }
}
</style>