<template>
    <div class="sfondo">
        <div class="container">
            <div class="slogan text-center pt-3 pb-3 text-light">
                Tour Privato & Esclusivo con Skipper - VIESTE
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.sfondo{
    background-image: linear-gradient(to bottom, #3b5471b4, #3b5471b4), url(../assets/isole-tremiti.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover
}

.slogan {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}
</style>