<template>
    <div class="sfondo footer pt-5 pb-4">
        <div class="container">

            <div class="d-flex justify-content-center pb-4">
                <img src="../assets/logo-ritagliato-cerchio.png" alt="" class="">
            </div>


            <div class="d-flex justify-content-center gap-5">

                <!-- <div class="d-flex justify-content-center">
                    <img src="../assets/logo-ritagliato-cerchio.png" alt="" class="">
                </div> -->

                <div>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#chi">Chi siamo</a></li>
                        <li><a href="#lei">Lei</a></li>
                        <li><a href="#cose">Itinerari</a></li>
                        <li><a href="#servizi">Servizi</a></li>
                        <li><a href="#domande">Domande frequenti</a></li>
                        <li><a href="#contattaci">Contattaci</a></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li class="fs-4"> <b>Social:</b> <br> <a href="https://www.instagram.com/seven.yacht/"><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mt-2"> <b>E-mail:</b> <br> seven.sevenyacht@gmail.com</li>
                        <li class="mt-2"> <b>Telefono:</b> <br> +39 3349177111</li>
                        <li class="mt-2"> <b>Whatsapp:</b> <br> +39 3349177111</li>

                    </ul>
                </div>

            </div>

            <hr>

            <div class="text-center mt-3">
                Dev by
            </div>
            <div class="text-center">
                <a href="https://luigi-dev.vercel.app/">Luigi Prencipe <i class="fa-solid fa-link"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.container {
    font-size: small;
}

b{
    font-size: medium;
}

a {
    text-decoration: none;
    color: white;
}

li {
    list-style-type: none;
    color: white;
}

.sfondo {
    background-color: #3b5471;
    color: white;
}

ul {
    padding-left: 0px;
    text-align: center;
}

img {
    border-radius: 50%;
    width: 120px;
    height: 120px;

}
</style>