<template>
    <div class="sfondo pb-5" id="contattaci">
        <div class="container cancella" id="cancella">
            <div class="titolo mt-3 pt-3">
                Contattaci!
            </div>

            <form ref="form" @submit.prevent="sendEmail">
                <div class="text-center mt-3">
                    <label>Nome</label> <br>
                    <input type="text" name="user_name" required>
                </div>
                <div class="text-center mt-3">
                    <label>Cognome</label> <br>
                    <input type="text" name="user_surname" required>
                </div>
                <div class="text-center mt-3">
                    <label>Email</label> <br>
                    <input type="email" name="user_email" required>
                </div>
                <div class="text-center mt-3">
                    <label>Messaggio</label> <br>
                    <textarea name="message" required></textarea>
                </div>

                <!-- <div class="d-none" id="caricamento">
                    <div class="loader"></div>
                </div>


                <div class="fw-bold text-success text-center" id="conferma">

                </div> -->

                <div class="text-center mt-3">
                    <button type="submit" value="Send" @click="caricamento()">Invia</button>
                </div>

            </form>
        </div>

        <div class="inviato">
            <div class="text-center conferma pt-5" id="invio">
                <i class="fa-solid fa-check email-success ms-2"></i> <br>
                <span> <b>Inviato!</b> </span> <br>
                <span>Ti ricontatteremo il prima possibile!</span>
            </div>
        </div>

    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    methods: {
        sendEmail() {
            emailjs.sendForm('service_jin951n', 'template_oh09gql', this.$refs.form, 'MVzrv-0OUp5sHtTUF')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    /* document.getElementById('conferma').innerHTML = "*Congratulazioni, email inviata con successo, la ricontatteremo il prima possibile!" */
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
        },

        caricamento() {
            var element = document.getElementById('cancella');
            element.classList.add("d-none");
            var elemento = document.getElementById('invio');
            elemento.classList.add("d-block")
        }

    }
}
</script>

<style lang="scss" scoped>
.conferma {
    color: #3b5471;
     display: none;
}

.email-success {
    font-size: 80px;
    display: block;
}

.sfondo {
    width: 100vw;
    background-color: #3b547127;
}

.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

input {
    width: 70%;
    height: 40px;
    padding-left: 10px;
    background-color: white;
    border: 2px solid #3b5471;
    border-radius: 6px;
}

textarea {
    width: 70%;
    height: 200px;
    padding: 10px;
    background-color: white;
    border: 2px solid #3b5471;
    border-radius: 6px;
}

label {
    font-weight: bold;
    color: #3b5471;
}

.caricamento-c {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
}

/* uiverse */
button {
    padding: 0.8em 1.8em;
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    background-color: #3b5471;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: #ffffff;
}

button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #586f8a;
    transition: .5s ease;
    display: block;
    z-index: -1;
}

button:hover::before {
    width: 105%;
}

button:hover {
    color: #ffffff;
}

.loader {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    border-radius: 50%;
}

.loader {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
}

.loader {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.hideMeAfter5Seconds {
    display: flex !important;
    justify-content: center !important;
    animation: hideAnimation 0s ease-in 0.6s;
    animation-fill-mode: forwards;
}

@keyframes hideAnimation {
    to {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}


@media screen and (min-width: 1024px) {

    .titolo {
        font-size: 45px;
    }

    .email-success {
        font-size: 110px;
        display: block;
    }

}

@media screen and (min-width: 1200px) {

    .titolo {
        font-size: 50px;
    }

}
</style>