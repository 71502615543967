<template>
    <div class="container pb-4">
        <div class="titolo text-center">
            Dicono di noi
        </div>
        <div class="d-flex flex-wrap justify-content-center align-items-center gap-3 mt-3">
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>

                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Timur
                    </div>
                    <div class="text-center">
                        Grazie al capitano Carmine e suo staff per ospitalità al bordo . Era una fantastica avventura
                        nonostante il tempo . Abbiamo visitato tanti posti meravigliosi della costa del Gargano . Grazie di
                        cuore ❤️
                    </div>
                </div>
            </div>
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>
                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Antonio
                    </div>
                    <div class="text-center ps-1 pe-1">
                        Bravissimi e gentilissimi. Davvero consigliato!
                    </div>
                </div>
            </div>
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>
                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Davide
                    </div>
                    <div class="text-center">
                        Comandante di lunga esperienza, accorto e disponibile, con un occhio sempre vigile al mare, ai
                        clienti e al suo equipaggio. Consigliato
                    </div>
                </div>
            </div>
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>
                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Emanuela
                    </div>
                    <div class="text-center">
                        Carmine è perfetto...attento, cordialissimo, disponibilissimo. Come essere ospiti a casa di amici di
                        vecchia data. Esperienza bellissima, da ripetere assolutamente.
                    </div>
                </div>
            </div>
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>
                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Fiorena
                    </div>
                    <div class="text-center">
                        Tutto perfetto!una splendida giornata che merita davvero ! Grazie a Carmine e Luigi!
                    </div>
                </div>
            </div>
            <div class="recensione p-2">
                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                    <div>
                        <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg"><img
                                src="../assets/clickNboat.png" alt="" class="logoRec"></a>
                    </div>
                    <div class="stelleCNB mt-2">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <div class="fw-bold">
                        Mike
                    </div>
                    <div class="text-center">
                        Siamo stati benissimo in questa escursione. Siamo stati benissimo! Carmine è molto ben informato e
                        appassionato di nautica e della zona. Una giornata di pieno relax e bagni nelle grotte! Consiglio
                        vivamente.
                    </div>
                </div>
            </div>

        </div>
        <div class="text-center mt-5">
            <a href="https://www.clickandboat.com/it/noleggio-barche/vieste/barca-a-motore/gobbi-375-sc-9k1dgg">Leggi di piu...</a>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.recensione {
    border: 1px solid #3b5471;
    width: 300px;
    height: 300px;
}

.logoRec {
    width: 40px;
}

.stelleCNB {
    color: #dd4d93;
}

.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

@media screen and (min-width:425px) {
    .recensione {
        width: 380px;
    }

}

@media screen and (min-width:768px) {

    .titolo {
        font-size: 45px;
    }
}

@media screen and (min-width:1200px) {
    .titolo {
        font-size: 50px;
    }
}</style>