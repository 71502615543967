<template>
    <div class="jumbo text-center" id="home">
        <div class="d-flex align-items-center justify-content-center flex-column h-100 gap-4">

            <!-- <div class="text-light titolo animate__animated animate__fadeInDown">
                Noleggio con Skipper
            </div>
            <div class="text-light fw-bold animate__animated animate__fadeInDown">
                Vivi un'esperienza unica a bordo di Seven Yacht 
            </div> -->
            <div class="animate__animated animate__fadeInDown">
                <img src="../assets/logo-ritagliato-cerchio.png" alt="" class="logo">
            </div>
            <div>
                <div class="animate__animated animate__fadeInDown text-light titolo">
                    Seven Yacht Charter <img src="../assets/stella3.png" alt="" class="stella">
                </div>
                <div class="animate__animated animate__fadeInDown text-light didascalia">
                    Vivi un esperienza magnifica con noi.
                </div>
            </div>

            <div class="animate__animated animate__fadeInDown">
                <a href="#contattaci">
                    <button>Contattaci</button>
                </a>

            </div>
        </div>
    </div>
</template>

<script>

import 'animate.css';

export default {

}


</script>

<style lang="scss" scoped>
.stella {
    width: 22px;
    position: fixed;
}

.jumbo {
    /* background-image: url(../assets/image1.jpeg) linear-gradient(90deg, #00000000 0%, #000000 100%); */
    /* background: linear-gradient(90deg, #00000000 0%, #000000 100%); */
    background-image:
        linear-gradient(to bottom, #3b5471a2, #3b5471a2), url(../assets/image1.jpeg);
    width: 100vw;
    height: 75vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 5px solid #3b5471;
}

.titolo {
    font-size: 30px;
    font-weight: 900;
}

.logo {
    width: 140px;
    border-radius: 55%;
}

/* ui verse */
button {
    padding: 0.8em 1.8em;
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    background-color: #3b5471;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: #ffffff;
}

button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #586f8a;
    transition: .5s ease;
    display: block;
    z-index: -1;
}

button:hover::before {
    width: 105%;
}

button:hover {
    color: #ffffff;
}

@media screen and (min-width:320px) {
    .titolo {
        font-size: 26px;
    }

    .stella {
        width: 15px;
        position: fixed;
    }
}
@media screen and (min-width:375px) {
    .titolo {
        font-size: 30px;
    }

    .stella {
        width: 18px;
        position: fixed;
    }
}

@media screen and (min-width:425px) {
    .titolo {
        font-size: 34px;
    }

    .stella {
        width: 20px;
        position: fixed;
    }
}

@media screen and (min-width:480px) {
    .titolo {
        font-size: 36px;
    }

    .logo {
        width: 150px;
        border-radius: 55%;
    }

    .stella {
        width: 23px;
        position: fixed;
        top: 4px;
    }
}

@media screen and (min-width:768px) {
    .titolo {
        font-size: 60px;
    }

    .logo {
        width: 160px;
        border-radius: 55%;
    }

    .didascalia {
        font-size: 20px;
    }

    .stella {
        width: 30px;
        position: fixed;
        top: 10px;
    }
}

@media screen and (min-width: 1024px) {
    .titolo {
        font-size: 65px;
    }

    .logo {
        width: 160px;
        border-radius: 55%;
    }
}

@media screen and (min-width: 1400px) {
    .titolo {
        font-size: 65px;
    }

    .logo {
        width: 160px;
        border-radius: 55%;
    }

    .stella {
        width: 32px;
        position: fixed;
        top: 10px;
    }
}

@media screen and (min-width: 1920px) {
    .titolo {
        font-size: 75px;
    }

    .logo {
        width: 190px;
        border-radius: 55%;
    }
}
</style>


