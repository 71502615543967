<template>
    <div class="container pb-4" id="domande">

        <div class="titolo mt-5 pb-4">
            Domande frequenti
        </div>

        <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Orari?
                    </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        Gli orari sono estrememante <span>personalizzabili</span> secondo le vostre esigenze. <br>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Fornite acqua a bordo?
                    </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body"><span>Sì</span>, non avete bisogno di portarvi casse e pesi inutili.
                        Forniamo acqua sia naturale che frizzante.</div>
                </div>
            </div>
            <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Il carburante?
                    </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Il carburante è <span>escluso</span>, con un consumo medio di 3l per miglio
                        nautico calcoliamo diverse cifre in base agli itinerari sopra riportati, nella loro descrizione
                        trovate maggiori dettagli.</div>
                </div>
            </div> -->
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        Si possono effettuare bagni?
                    </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body"><span>Certo!</span> <br> è parte principale di ogni nostra escursione avere la
                        possibilità di calarsi all'interno delle nostre acque in ogni luogo che visiteremo</div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                        Per maggiori dettagli?
                    </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        Per maggiori dettagli non esitate a contattarci: <br>
                        <span>Telefono:</span> +39 3349177111 <br>
                        <span>Email:</span> seven.sevenyacht@gmail.com <br>
                        Oppure scriveteci nel form qui in basso. <i class="fa-solid fa-turn-down"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.titolo {
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
    font-family: 'Dancing Script', cursive;
    font-size: 40px;
    color: #3b5471;
    font-weight: bolder;
}

span {
    font-weight: 900;
    color: #3b5471;
}

button {
    color: #3b5471;
    font-weight: bolder;
}


@media screen and (min-width: 1024px) {
    .titolo {
        font-size: 45px;
    }
}

@media screen and (min-width: 1200px) {
    .titolo {
        font-size: 50px;
    }
}</style>